import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { IPageData } from "@model/page-data";
import { IGetLocations } from "@model/stmt/GetLocations";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { faFacebook, faInstagram, faLinkedin, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

@Component({
	selector: "cm-footer-design-force",
	templateUrl: "./footer-design-force.component.html",
	styleUrls: ['./footer-design-force.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterDesignForceComponent {
	copyrightYear = new Date().getFullYear();
	dealer: string;
	locations: IGetLocations[];
	faThumbsUp = faThumbsUp;
	faFacebook = faFacebook;
	faInstagram= faInstagram;
	faLinkedin = faLinkedin;
	faLinkedinIn = faLinkedinIn;
	bulletPoints = [
		"Web Design & Development",
		"Photography & Videography",
		"Reputation Management",
		"Print Materials",
		"Advertising Management",
		"Social Media Management",
		"Logos & Branding",
	];
	partners = [
		"https://dms.rvimg.com/s3static/12/bing-m.png",
		"https://dms.rvimg.com/s3static/12/facebook-partners-m.png",
		"https://dms.rvimg.com/s3static/12/google-partner-m.png",
	];
	constructor(@Inject("PAGE_DATA") private pageData: IPageData) {
		this.dealer = this.pageData.appInfo.data.siteName;
		this.locations = this.pageData.locations;
	}
}
